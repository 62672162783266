var render = function () {
  var _vm$paket, _vm$result, _vm$result$jurusan_pi, _vm$result2, _vm$result2$jurusan_p, _vm$result2$jurusan_p2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('header', [_c('h4', {
    staticClass: "text-center"
  }, [_c('strong', [_vm._v(" Laporan Tryout " + _vm._s((_vm$paket = _vm.paket) === null || _vm$paket === void 0 ? void 0 : _vm$paket.name) + " ")])])]), _c('hr'), _c('section', [_c('table', {
    staticClass: "w-100"
  }, [_c('tr', [_c('td', [_vm._v("Nama")]), _c('th', [_vm._v(":")]), _c('th', [_vm.siswa ? _c('span', [_vm._v(_vm._s(_vm.siswa.name))]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Nama siswa tidak ditemukan ")])])]), _c('tr', [_c('td', [_vm._v("Asal Sekolah")]), _c('th', [_vm._v(":")]), _c('th', [_vm.siswa && _vm.siswa.detail && _vm.siswa.detail.school_origin_info ? _c('span', [_vm._v(" " + _vm._s(_vm.siswa.detail.school_origin_info.nama_sekolah) + " ")]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Sekolah siswa tidak ditemukan ")])])]), _vm.jadwal ? _c('tr', [_c('td', [_vm._v("Info Jadwal")]), _c('th', [_vm._v(":")]), _c('th', [_vm._v(" " + _vm._s(_vm.jadwal.name) + " -> " + _vm._s(_vm.jadwal.start_time) + " s/d " + _vm._s(_vm.jadwal.end_time) + " ")])]) : _vm._e(), (_vm$result = _vm.result) !== null && _vm$result !== void 0 && (_vm$result$jurusan_pi = _vm$result.jurusan_pilihan) !== null && _vm$result$jurusan_pi !== void 0 && _vm$result$jurusan_pi.school ? _c('tr', [_c('td', [_vm._v("Pilihan Sekolah Kedinasan")]), _c('th', [_vm._v(":")]), _c('th', [_vm._v(" " + _vm._s((_vm$result2 = _vm.result) === null || _vm$result2 === void 0 ? void 0 : (_vm$result2$jurusan_p = _vm$result2.jurusan_pilihan) === null || _vm$result2$jurusan_p === void 0 ? void 0 : (_vm$result2$jurusan_p2 = _vm$result2$jurusan_p.school) === null || _vm$result2$jurusan_p2 === void 0 ? void 0 : _vm$result2$jurusan_p2.name) + " ")])]) : _vm._e()])])]), _c('b-card-actions', {
    ref: "hasil-card",
    attrs: {
      "title": "Hasil Try Out",
      "action-collapse": ""
    }
  }, [_c('b-table-simple', {
    attrs: {
      "bordered": "",
      "responsive": "",
      "hover": "",
      "striped": ""
    }
  }, _vm._l(_vm.subtests, function (subtest, i) {
    var _subtest$bobot_info, _subtest$bobot_info2;

    return _c('b-tr', {
      key: i
    }, [_c('b-td', {
      attrs: {
        "colspan": "1"
      }
    }, [subtest.is_parent || subtest.is_sum ? _c('strong', {
      class: subtest.is_sum ? 'd-block text-primary' : 'text-dark'
    }, [_vm._v(" " + _vm._s(subtest.name) + " "), subtest.is_sum && subtest.is_using_percentage ? _c('i', [_vm._v("( passing grade: " + _vm._s(subtest.passing_grade) + " )")]) : _vm._e()]) : _c('span', {
      staticClass: "d-block"
    }, [_vm._v(" " + _vm._s(subtest.name) + " "), subtest.is_using_percentage ? _c('i', {
      staticClass: "text-danger"
    }, [_vm._v("(" + _vm._s(parseFloat((_subtest$bobot_info = subtest.bobot_info) === null || _subtest$bobot_info === void 0 ? void 0 : _subtest$bobot_info.bobot) * 100) + "%)")]) : _vm._e()])]), _c('b-td', {
      staticClass: "text-center"
    }, [!subtest.is_parent ? _c('strong', [_vm._v(_vm._s(subtest.nilai))]) : _c('span', [_vm._v("Nilai")])]), _c('b-td', {
      staticClass: "text-center"
    }, [subtest.is_using_percentage ? _c('strong', [subtest.is_parent ? _c('span', [_vm._v("Nilai x Bobot %")]) : !subtest.is_sum ? _c('span', [_vm._v(" " + _vm._s(parseFloat(subtest.nilai) * parseFloat((_subtest$bobot_info2 = subtest.bobot_info) === null || _subtest$bobot_info2 === void 0 ? void 0 : _subtest$bobot_info2.bobot)) + " ")]) : _vm._e()]) : _vm._e()]), _c('b-td', {
      staticClass: "text-center"
    }, [!subtest.is_parent ? _c('strong', {
      class: subtest.is_passed ? 'text-success' : 'text-danger'
    }, [_vm._v(" " + _vm._s(subtest.is_passed ? "LULUS" : "TIDAK LULUS") + " ")]) : _vm._e()])], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }