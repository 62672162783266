var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "justify-content-center"
  }, [_vm.paket ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "10"
    }
  }, [_vm.paket && _vm.paket.rasionalisasi_type === 'utbk' ? _c('section', [_c('result-utbk', {
    attrs: {
      "result": _vm.result
    }
  })], 1) : _vm._e(), _vm.paket && _vm.paket.rasionalisasi_type === 'mandiri' ? _c('section', [_c('result-mandiri', {
    attrs: {
      "result": _vm.result
    }
  })], 1) : _vm._e(), _vm.paket && _vm.paket.rasionalisasi_type === 'sma' ? _c('section', [_c('result-sma', {
    attrs: {
      "result": _vm.result
    }
  })], 1) : _vm._e(), _vm.paket && _vm.paket.rasionalisasi_type === 'kedinasan' ? _c('section', [_c('result-kedinasan', {
    attrs: {
      "result": _vm.result
    }
  })], 1) : _vm._e(), _vm.paket && _vm.paket.rasionalisasi_type === 'bintara' ? _c('section', [_c('result-bintara', {
    attrs: {
      "result": _vm.result
    }
  })], 1) : _vm._e()]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }