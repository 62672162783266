var render = function () {
  var _vm$paket, _vm$result, _vm$result2, _vm$result3, _vm$result4, _vm$result4$ranking;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('header', [_c('h4', {
    staticClass: "text-center"
  }, [_c('strong', [_vm._v(" Laporan Tryout " + _vm._s((_vm$paket = _vm.paket) === null || _vm$paket === void 0 ? void 0 : _vm$paket.name) + " ")])])]), _c('hr'), _c('section', [_c('table', {
    staticClass: "w-100"
  }, [_c('tr', [_c('td', [_vm._v("Nama")]), _c('th', [_vm._v(":")]), _c('th', [_vm.siswa ? _c('span', [_vm._v(_vm._s(_vm.siswa.name))]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Nama siswa tidak ditemukan ")])])]), _c('tr', [_c('td', [_vm._v("Asal Sekolah")]), _c('th', [_vm._v(":")]), _c('th', [_vm.siswa && _vm.siswa.detail && _vm.siswa.detail.school_origin_info ? _c('span', [_vm._v(" " + _vm._s(_vm.siswa.detail.school_origin_info.nama_sekolah) + " ")]) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Sekolah siswa tidak ditemukan ")])])]), _vm.jadwal ? _c('tr', [_c('td', [_vm._v("Info Jadwal")]), _c('th', [_vm._v(":")]), _c('th', [_vm._v(" " + _vm._s(_vm.jadwal.name) + " -> " + _vm._s(_vm.jadwal.start_time) + " s/d " + _vm._s(_vm.jadwal.end_time) + " ")])]) : _vm._e()])])]), _c('b-card-actions', {
    ref: "hasil-card",
    attrs: {
      "title": "Hasil Try Out",
      "action-collapse": ""
    }
  }, [_c('b-table-simple', {
    attrs: {
      "bordered": "",
      "striped": "",
      "hover": "",
      "responsive": ""
    }
  }, [_c('b-tr', [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("TRY OUT")]), _c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("NILAI PER MAPEL SATUAN")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-center text-danger"
  }, [_vm._v("BENAR")]), _c('b-th', {
    staticClass: "text-center text-danger"
  }, [_vm._v("SALAH")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("NILAI TO")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("*KK")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("*KET")])], 1), _vm._l(_vm.result.nilai_per_mapel, function (item, i) {
    return _c('b-tr', {
      key: i
    }, [_c('b-th', [_vm._v(_vm._s(item.ujian_name))]), _c('b-th', [_vm._v(_vm._s(item.jumlah_benar))]), _c('b-th', [_vm._v(_vm._s(item.jumlah_salah))]), _c('b-th', [_vm._v(_vm._s(item.nilai))]), _c('b-th', [_vm._v(_vm._s(item.grade))]), _c('b-th', [_vm._v(_vm._s(item.keterangan))])], 1);
  })], 2), _c('hr'), _c('b-table-simple', {
    attrs: {
      "bordered": "",
      "striped": "",
      "hover": "",
      "responsive": "",
      "table-variant": "warning"
    }
  }, [_c('b-tr', [_c('b-th', {
    staticClass: "text-right"
  }, [_vm._v("Jumlah Nilai")]), _c('b-td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$result = _vm.result) === null || _vm$result === void 0 ? void 0 : _vm$result.jumlah_nilai))])])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-right"
  }, [_vm._v("Rata-rata Nilai")]), _c('b-td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$result2 = _vm.result) === null || _vm$result2 === void 0 ? void 0 : _vm$result2.nilai_rata))])])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-right"
  }, [_vm._v("*KK Rata-rata Nilai")]), _c('b-td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$result3 = _vm.result) === null || _vm$result3 === void 0 ? void 0 : _vm$result3.nilai_rata_grade))])])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-right"
  }, [_vm._v("Ranking")]), _c('b-td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_c('strong', [_vm._v(_vm._s((_vm$result4 = _vm.result) === null || _vm$result4 === void 0 ? void 0 : (_vm$result4$ranking = _vm$result4.ranking) === null || _vm$result4$ranking === void 0 ? void 0 : _vm$result4$ranking.ke))])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }