<template>
    <section>
        <b-card class="mb-2">
            <header>
                <h4 class="text-center">
                    <strong>
                        Laporan Tryout {{ paket?.name }}
                    </strong>
                </h4>
            </header>
            <hr>
            <section>
                <table class="w-100">
                    <tr>
                        <td>Nama</td>
                        <th>:</th>
                        <th>
                            <span v-if="siswa">{{ siswa.name }}</span>
                            <i class="text-danger" v-else>
                                Nama siswa tidak ditemukan
                            </i>
                        </th>
                    </tr>
                    <tr>
                        <td>Asal Sekolah</td>
                        <th>:</th>
                        <th>
                            <span v-if="siswa && siswa.detail && siswa.detail.school_origin_info">
                                {{ siswa.detail.school_origin_info.nama_sekolah }}
                            </span>
                            <i class="text-danger" v-else>
                                Sekolah siswa tidak ditemukan
                            </i>
                        </th>
                    </tr>
                    <tr v-if="jadwal">
                        <td>Info Jadwal</td>
                        <th>:</th>
                        <th>
                            {{jadwal.name}} -> {{ jadwal.start_time }} s/d {{ jadwal.end_time }}
                        </th>
                    </tr>
                </table>
            </section>
        </b-card>

        <b-card-actions
            ref="hasil-card"
            title="Hasil Try Out"
            action-collapse
        >
            <b-table-simple bordered striped hover responsive >
                <b-tr>
                    <b-th class="text-center" rowspan="2">TRY OUT</b-th>
                    <b-th class="text-center" colspan="5">NILAI PER MAPEL SATUAN</b-th>
                </b-tr>
                <b-tr>
                    <b-th class="text-center text-danger">BENAR</b-th>
                    <b-th class="text-center text-danger">SALAH</b-th>
                    <b-th class="text-center">NILAI TO</b-th>
                    <b-th class="text-center">*KK</b-th>
                    <b-th class="text-center">*KET</b-th>
                </b-tr>

                <b-tr
                    v-for="(item, i) in result.nilai_per_mapel"
                    :key="i"
                >
                    <b-th>{{ item.ujian_name }}</b-th>
                    <b-th>{{ item.jumlah_benar }}</b-th>
                    <b-th>{{ item.jumlah_salah }}</b-th>
                    <b-th>{{ item.nilai }}</b-th>
                    <b-th>{{ item.grade }}</b-th>
                    <b-th>{{ item.keterangan }}</b-th>
                </b-tr>
            </b-table-simple>
            <hr>
            <b-table-simple bordered striped hover responsive table-variant="warning">
                <b-tr>
                    <b-th class="text-right">Jumlah Nilai</b-th>
                    <b-td colspan="2" class="text-center"><strong>{{ result?.jumlah_nilai }}</strong></b-td>
                </b-tr>
                <b-tr>
                    <b-th class="text-right">Rata-rata Nilai</b-th>
                    <b-td colspan="2" class="text-center"><strong>{{ result?.nilai_rata }}</strong></b-td>
                </b-tr>
                <b-tr>
                    <b-th class="text-right">*KK Rata-rata Nilai</b-th>
                    <b-td colspan="2" class="text-center"><strong>{{ result?.nilai_rata_grade }}</strong></b-td>
                </b-tr>
                <b-tr>
                    <b-th class="text-right">Ranking</b-th>
                    <b-td colspan="2" class="text-center"><strong>{{ result?.ranking?.ke }}</strong></b-td>
                </b-tr>
            </b-table-simple>
        </b-card-actions>
    </section>
</template>
<script>
import {BRow, BCol, BCard, BTableSimple, BTr, BTh, BTd} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
export default {
    props: {
        result: {
            type: Object,
            require: true
        }
    },
    components: {
        BRow, BCol, BCard, BTableSimple, BTr, BTh, BTd, BCardActions
    },
    computed: {
        paket() {
            return this.$store.state.rasionalisasi.currentPaket
        },
        isPaketContainSubcat() {
            return this.paket && this.paket.is_contain_subcat == 1
        },
        jadwal() {
            return this.$store.state.rasionalisasi.currentJadwal
        },
        siswa() {
            return this.$store.state.rasionalisasi.currentSiswa
        },
    },
    data: () => ({
        subtests: []
    })
}
</script>