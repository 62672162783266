<template>
  <section>
    <b-card class="mb-2">
      <header>
        <h4 class="text-center">
          <strong> Laporan Tryout {{ paket?.name }} </strong>
        </h4>
      </header>
      <hr />
      <section>
        <table class="w-100">
          <tr>
            <td>Nama</td>
            <th>:</th>
            <th>
              <span v-if="siswa">{{ siswa.name }}</span>
              <i class="text-danger" v-else> Nama siswa tidak ditemukan </i>
            </th>
          </tr>
          <tr>
            <td>Asal Sekolah</td>
            <th>:</th>
            <th>
              <span
                v-if="siswa && siswa.detail && siswa.detail.school_origin_info"
              >
                {{ siswa.detail.school_origin_info.nama_sekolah }}
              </span>
              <i class="text-danger" v-else> Sekolah siswa tidak ditemukan </i>
            </th>
          </tr>
          <tr v-if="jadwal">
            <td>Info Jadwal</td>
            <th>:</th>
            <th>
              {{ jadwal.name }} -> {{ jadwal.start_time }} s/d
              {{ jadwal.end_time }}
            </th>
          </tr>
          <tr v-if="result?.jurusan_pilihan?.school">
            <td>Pilihan Sekolah Kedinasan</td>
            <th>:</th>
            <th>
              {{ result?.jurusan_pilihan?.school?.name }}
            </th>
          </tr>
        </table>
      </section>
    </b-card>

    <b-card-actions ref="hasil-card" title="Hasil Try Out" action-collapse>
      <b-table-simple bordered responsive hover striped>
        <b-tr v-for="(subtest, i) in subtests" :key="i">
          <b-td colspan="1">
            <strong
              :class="subtest.is_sum ? 'd-block text-primary' : 'text-dark'"
              v-if="subtest.is_parent || subtest.is_sum"
            >
              {{ subtest.name }}
              <i v-if="subtest.is_sum && subtest.is_using_percentage"
                >( passing grade: {{ subtest.passing_grade }} )</i
              >
            </strong>
            <span class="d-block" v-else>
              {{ subtest.name }}
              <i v-if="subtest.is_using_percentage" class="text-danger"
                >({{ parseFloat(subtest.bobot_info?.bobot) * 100 }}%)</i
              >
            </span>
          </b-td>
          <b-td class="text-center">
            <strong v-if="!subtest.is_parent">{{ subtest.nilai }}</strong>
            <span v-else>Nilai</span>
          </b-td>
          <b-td class="text-center">
            <strong v-if="subtest.is_using_percentage">
              <span v-if="subtest.is_parent">Nilai x Bobot %</span>
              <span v-else-if="!subtest.is_sum">
                {{
                  parseFloat(subtest.nilai) *
                  parseFloat(subtest.bobot_info?.bobot)
                }}
              </span>
            </strong>
          </b-td>
          <b-td class="text-center">
            <strong
              v-if="!subtest.is_parent"
              :class="subtest.is_passed ? 'text-success' : 'text-danger'"
            >
              {{ subtest.is_passed ? "LULUS" : "TIDAK LULUS" }}
            </strong>
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-card-actions>
  </section>
</template>
<script>
import { BRow, BCol, BCard, BTableSimple, BTr, BTh, BTd } from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
export default {
  props: {
    result: {
      type: Object,
      require: true,
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BCardActions,
  },
  computed: {
    paket() {
      return this.$store.state.rasionalisasi.currentPaket;
    },
    isPaketContainSubcat() {
      return this.paket && this.paket.is_contain_subcat == 1;
    },
    jadwal() {
      return this.$store.state.rasionalisasi.currentJadwal;
    },
    siswa() {
      return this.$store.state.rasionalisasi.currentSiswa;
    },
  },
  data: () => ({
    subtests: [],
  }),
  mounted() {
    if (this.result) {
      const { subcats } = this.result;
      const res = [];
      const items = subcats.forEach((subcat) => {
        const subcatMarkAsPsikotest = subcat.is_using_percentage == 1;

        const subtestItems = subcat.items.map((subtest) => {
          return {
            id: subtest.id,
            name: subtest.ujian_name,
            bobot_info: subtest.bobot_info,
            is_passed: subtest.is_passed,
            is_using_bobot_percentage: subtest.is_using_bobot_percentage,
            is_using_percentage: subcatMarkAsPsikotest,
            nilai: subtest.nilai,
            passing_grade: subtest.passing_grade,
            subcat_id: subtest.subcat_id,
            ujian_id: subtest.ujian_id,
            is_parent: false,
          };
        });

        // create an acumulation nilai and lulus
        const mySubtest = subtestItems.filter(
          (subtest) => !subtest.is_parent && subtest?.subcat_id == subcat.id
        );

        // get sum nilai untuk subcat yg tidak ditandai psikotest
        const sumNilai = mySubtest.reduce((total, subtest) => {
          total += parseFloat(subtest.nilai);
          return total;
        }, 0);

        // get sum final niilai untuk subcat yg ditandai psikotest
        const sumFinalNilai = parseFloat(subcat.sum_percentage_nilai);
        const isSubcatPassed = subcat.is_passed;

        res.push({
          name: subcat.name,
          is_parent: true,
          is_using_percentage: subcat.is_using_percentage,
        });
        res.push(...subtestItems);
        res.push({
          name: `SKOR ${subcat.name}`,
          is_parent: false,
          nilai: subcatMarkAsPsikotest ? sumFinalNilai : sumNilai,
          is_passed: isSubcatPassed,
          is_sum: true,
          passing_grade: subcat.passing_grade,
          is_using_percentage: subcat.is_using_percentage,
        });
      });

      this.subtests = res;
    }
  },
};
</script>
